import styled from 'styled-components';

// Componente Styled para o rodapé
export const FooterContainer = styled.footer`
background-color: #f1f1f1;
color: #6e6e6e;
padding: 20px 50px;
display: flex;
justify-content: space-between;
flex-wrap: wrap;
`;

// Componente Styled para as colunas
export const FooterColumn = styled.div`
  flex: 1;
  min-width: 200px;
  margin: 10px 0;

  h4 {
    margin-bottom: 15px;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      margin-bottom: 10px;
    }
  }

  a {
    color: #6e6e6e;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const DevSite = styled.div`
padding: 10px;
    text-align: center;
    background: #d9d9d9;
    color: #787878;

    strong{
      color: #410070;
    }
`;