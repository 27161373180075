import React, { useState } from 'react';
import { HeaderContainer, HeaderMobile, MenuMobile, AmburguerMenu, Logo, Menu, MenuItem, Overlay, Sidebar, SidebarContent } from './styled';
import logotipo from "../imagens/logot.png";
import { CiMenuBurger } from "react-icons/ci";

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <HeaderContainer id="home">
        <Logo>
          <img src={logotipo} alt="Logotipo" />
        </Logo>
        <Menu>
          <MenuItem href="#home">Home</MenuItem>
          <MenuItem href="#about">Sobre nós</MenuItem>
          <MenuItem href="#services">Serviços</MenuItem>
          <MenuItem href="#contact">Contatos</MenuItem>
        </Menu>
      </HeaderContainer>

      <HeaderMobile id="home">
        <Logo>
          <img src={logotipo} alt="Logotipo" />
        </Logo>
        <MenuMobile>
          <AmburguerMenu onClick={toggleMenu}>
            <CiMenuBurger />
          </AmburguerMenu>
        </MenuMobile>
      </HeaderMobile>

      <Overlay isOpen={isMenuOpen} onClick={toggleMenu} />

      <Sidebar isOpen={isMenuOpen}>
        <SidebarContent>
          <a href="#home" onClick={toggleMenu}>Home</a>
          <a href="#about" onClick={toggleMenu}>Sobre Nós</a>
          <a href="#services" onClick={toggleMenu}>Serviços</a>
          <a href="#contact" onClick={toggleMenu}>Contato</a>
        </SidebarContent>
      </Sidebar>
    </>
  );
};

export default Header;
