import React from 'react';
import { BannerContainer, Description, DescriptionContainer, FloatingImage, DisplayFlex, ImageContainer, Title, WhatsappButton, Item } from './styled';
import homem from "../imagens/homem2.png"
import facebook from "../imagens/facebook.png"
import tiktok from "../imagens/tiktok.jpg"
import instagram from "../imagens/instagram.png"
import linkedin from "../imagens/linkedin.png"
const Container = () => {
    return (
      <>
      <BannerContainer>

      <ImageContainer>
        <FloatingImage src={homem} alt="Imagem Flutuante" />
      </ImageContainer>

      <DescriptionContainer>
        <Title>Serviços Completo</Title>
        <Description>de Limpeza de Terrenos</Description>
        <span>Transforme Seu Terreno com Nossa Limpeza Especializada</span>
        <br/>
        <WhatsappButton href="https://wa.me/5511977120701?text=Gostaria%20de%20fazer%20um%20or%C3%A7amento.">Whatsapp</WhatsappButton>
        <br/>
        <DisplayFlex>
            <Item>
                <a href='https://www.facebook.com/profile.php?id=100092727708430'>
                  <img src={facebook} />
                </a>
            </Item>
            <Item>
                <img src={tiktok} />
            </Item>
            <Item>
              <a href='https://www.instagram.com/limpgoodservico/'>
                <img src={instagram} />
              </a>
            </Item>
            <Item>
              <a href='https://www.linkedin.com/in/limpgood-servi%C3%A7os-b1329928b/'>
                <img src={linkedin} />
              </a>
            </Item>
        </DisplayFlex>
      </DescriptionContainer>

    </BannerContainer>
      </>
    );
  };
  
  export default Container;