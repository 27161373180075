import React from 'react';
import { FooterColumn, FooterContainer, DevSite } from './styled';

import URL_DA_LOGO from "../imagens/logot.png";


const Footer = () => {
    return (
      <>
      <FooterContainer id="contact">
        {/* Coluna do logo */}
        <FooterColumn>
          <h4>Logo</h4>
          <img src={URL_DA_LOGO} alt="Logo" style={{ width: '150px' }} />
        </FooterColumn>
  
        {/* Coluna do menu */}
        <FooterColumn>
          <h4>Menu</h4>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#about">Sobre Nós</a></li>
            <li><a href="#services">Serviços</a></li>
            <li><a href="#contact">Contato</a></li>
          </ul>
        </FooterColumn>
  
        {/* Coluna de localização */}
        <FooterColumn>
          <h4>Localização</h4>
          <p>Rua: 123 Cândido Rodrigues, 183</p>
          <p>Scaffid II, Itaquaquecetuba - SP</p>
          <p>CEP: 12345-678</p>
        </FooterColumn>
  
        {/* Coluna das redes sociais */}
        <FooterColumn>
          <h4>Redes Sociais</h4>
          <ul>
            <li><a href="https://www.facebook.com/profile.php?id=100092727708430">Facebook</a></li>
            <li><a href="https://www.instagram.com/limpgoodservico/">Instagram</a></li>
            <li><a href="#twitter">Twitter</a></li>
            <li><a href="https://www.linkedin.com/in/limpgood-servi%C3%A7os-b1329928b/">LinkedIn</a></li>
          </ul>
        </FooterColumn>
      </FooterContainer>
      <DevSite><span>Desenvolvido por <strong>GeminiPlus</strong></span></DevSite>
      </>
    );
  };
  
  export default Footer;