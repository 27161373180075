import {useState, useEffect} from 'react';
import { Card, ContainerOutrosServico, DisplayFlex, ConatinerCards, ServicoContainer, Titulo, FixedDiv, QRCodeImage, ToggleButton, FacebookImage, FacebookDiv, ContainerOutrosServicoMobile } from './styled';

import img from "../imagens/rocadeira.jpg"
import cortaArvore from "../imagens/cortaArvore.jpg"
import podarArvore from "../imagens/podarArvore.jpg"
import homem3 from "../imagens/homem5.png"
import URL_DA_IMAGEM_QR_CODE from "../imagens/QRcode.jpg"
import URL_DA_IMAGEM_DO_FACEBOOK from "../imagens/facebook.png"

const Servico = () => {

  const [isMinimized, setIsMinimized] = useState(false);

  const handleToggle = () => {
    setIsMinimized(!isMinimized);
  };

  useEffect(() => {
    if (window.FB) {
      window.FB.XFBML.parse();
    }
  }, []);

    return (
      <>
        <ServicoContainer>
          <h1 style={{ color: "#3d59d8" }}>Um pouco sobre nós</h1>
          <ContainerOutrosServico id="about">
            <DisplayFlex>
              <div className='conteudo-card-one'>
                <div className='fonttitulo'>
                  <label>Quem somos</label>
                </div>
                <br />
                <div className='span'>
                  <span>
                    Fundada em 2023 por dois irmãos jovens, nossa empresa nasceu com o propósito de oferecer serviços de alta qualidade a preços justos. Temos o imenso prazer em garantir que seu ambiente seja sempre organizado, limpo e livre de pragas. Desde que começamos, fomos surpreendidos pela alta demanda, o que reforça nosso compromisso com o atendimento dedicado, feito com amor e profissionalismo. Estamos à disposição para ajudar a melhorar seu espaço, oferecendo serviços de manutenção e limpeza de terrenos e quintais.
                    Ainda estamos crescendo e, por isso, estamos trabalhando ainda mais para expandir nossos serviços. Em breve, ofereceremos <strong>terraplanagem</strong>, <strong>poda de árvores</strong>, <strong>paisagismo</strong> e muito mais. Não apenas cuidaremos da limpeza do seu ambiente, mas também da sua residência, com serviços básicos de manutenção e limpeza.
                  </span>
                </div>
              </div>
              <div className='img'>
                <img src={homem3} style={{ position: "relative", left: "-10px", top: 4 }} />
              </div>
            </DisplayFlex>
          </ContainerOutrosServico>

          <ContainerOutrosServicoMobile id="about">
            <div className='conteudo-mob'>
              <div className='titulo-mob'>
                <label>Quem somos</label>
              </div>
              <div className='text-mob'>
                <span>
                Fundada em 2023 por dois irmãos jovens, nossa empresa nasceu com o propósito de oferecer serviços de alta qualidade a preços justos. Temos o imenso prazer em garantir que seu ambiente seja sempre organizado, limpo e livre de pragas. Desde que começamos, fomos surpreendidos pela alta demanda, o que reforça nosso compromisso com o atendimento dedicado, feito com amor e profissionalismo. Estamos à disposição para ajudar a melhorar seu espaço, oferecendo serviços de manutenção e limpeza de terrenos e quintais.
                    Ainda estamos crescendo e, por isso, estamos trabalhando ainda mais para expandir nossos serviços. Em breve, ofereceremos <strong>terraplanagem</strong>, <strong>poda de árvores</strong>, <strong>paisagismo</strong> e muito mais. Não apenas cuidaremos da limpeza do seu ambiente, mas também da sua residência, com serviços básicos de manutenção e limpeza.
                </span>
              </div>
            </div>
          </ContainerOutrosServicoMobile>

          <Titulo id="services">Conheça nossos serviços básicos</Titulo>

          <ConatinerCards>
            <Card>
              <div className='containerImg'>
                <img src={img} />
              </div>

              <div>
                <div className='font-titulo'>
                  <label>Limpeza de <br /> terreno e quintal</label>
                </div>

                <div className='span'>
                  <span>
                    Removemos do terreno qualquer material indesejável ou que atrapalhe o andamento da obra, como por exemplo, vegetação, entulho, lixo, etc.
                  </span>
                </div>

                <div className='btn'>
                  <a href='https://wa.me/5511977120701?text=Gostaria%20de%20fazer%20um%20or%C3%A7amento.'>Saiba mais</a>
                </div>
              </div>
            </Card>

            <Card>
              <div className='containerImg'>
                <img src={podarArvore} />
              </div>

              <div>
                <div className='font-titulo'>
                  <label>Manutenção de <br /> Jardim</label>
                </div>

                <div className='span'>
                  <span>
                    Realizamos a manutenção completa de seu jardim, através de corte de grama, adubações, controle de pragas. Entre em contato e faça uma avaliação.
                  </span>
                </div>

                <div className='btn'>
                  <a href='https://wa.me/5511977120701?text=Gostaria%20de%20fazer%20um%20or%C3%A7amento.'>Saiba mais</a>
                </div>
              </div>
            </Card>
          </ConatinerCards>
        </ServicoContainer>

        <FixedDiv isMinimized={isMinimized}>
          <ToggleButton onClick={handleToggle}>
            {isMinimized ? '+' : '×'}
          </ToggleButton>
          <QRCodeImage
            src={URL_DA_IMAGEM_QR_CODE}
            alt="QR Code"
            isMinimized={isMinimized}
          />
        </FixedDiv>

        <FacebookDiv>
          <a href="https://www.facebook.com/limpgood" target="_blank" rel="noopener noreferrer">
            <FacebookImage
              src={URL_DA_IMAGEM_DO_FACEBOOK}
              alt="Facebook"
            />
          </a>
          <div className='alinhaSpan'>
            <span className='textFace'>Nossa pagina</span>
          </div>
        </FacebookDiv>


      {/**  <div
      className="fb-page"
      data-href="https://www.facebook.com/limpgood" // Substitua pelo URL da sua página
      data-tabs="timeline"
      data-width=""
      data-height=""
      data-small-header="false"
      data-adapt-container-width="true"
      data-hide-cover="false"
      data-show-facepile="true">
      <blockquote cite="https://www.facebook.com/limpgood" className="fb-xfbml-parse-ignore">
        <a href="https://www.facebook.com/limpgood">Your Page Name</a>
      </blockquote>
    </div> */}


      </>
    );
  };
  
  export default Servico;