import './App.css';
import Header from './components/header';
import Container from './components/container';
import Servico from './components/serviços';
import Footer from './components/footer';

function App() {
  document.title = "Limpgood"
  return (
    <>
    <Header/>
    <Container/>
    <Servico />
    <Footer />
    </>
  );
}

export default App;
