import styled, { keyframes } from 'styled-components';

// Animação para a imagem flutuante
const float = keyframes`
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
`;

// Contêiner principal com fundo branco e parte verde arredondada no lado direito
export const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #fff;
  height: auto;
  position: relative;
  overflow: hidden; /* Esconde parte do verde que se estende além do contêiner */

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 40%; /* Ajuste conforme a necessidade */
    height: 100%;
    border-top-left-radius: 100px; /* Arredondamento do lado direito */
    border-bottom-left-radius: 100px; /* Arredondamento do lado direito */
    z-index: -1;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;

    &:after {
      width: 50%; /* Ajusta o tamanho no mobile */
      border-top-left-radius: 60px; /* Arredondamento menor no mobile */
      border-bottom-left-radius: 60px; /* Arredondamento menor no mobile */
    }
  }

  @media (max-width: 480px) {
    &:after {
      width: 100%; /* Verde ocupa a tela toda no mobile */
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  margin-right: 20px;
  text-align: center;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

export const FloatingImage = styled.img`
  width: 70%;
  height: auto;
  border-radius: 60%;
  animation: ${float} 3s ease-in-out infinite;

  @media (max-width: 768px) {
    width: 50%;
  }

  @media (max-width: 480px) {
    width: 70%;
  }
`;

export const GlassCircle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);

  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
  }

  @media (max-width: 480px) {
    width: 100px;
    height: 100px;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    color: #5a5858;
    font-size: 18px;
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const Title = styled.h1`
  font-size: 2.4em;
  color: #24A71C;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 2em;
  }

  @media (max-width: 480px) {
    font-size: 1.6em;
  }
`;

export const Description = styled.p`
  font-size: 2.4em;
  color: #3D59D8;
  margin: 0;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 2em;
  }

  @media (max-width: 480px) {
    font-size: 1.6em;
  }
`;

export const WhatsappButton = styled.a`
  all: unset;
  width: 100px;
  height: 30px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &::after,
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -99999;
    transition: all 0.4s;
  }

  &::before {
    transform: translate(0%, 0%);
    width: 100%;
    height: 90%;
    background: #24A71C;
    border-radius: 8px;
  }

  &::after {
    transform: translate(10px, 10px);
    width: 35px;
    height: 35px;
    background: #ffffff15;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 50px;
  }

  &:hover::before {
    transform: translate(5%, 20%);
    width: 110%;
    height: 110%;
  }

  &:hover::after {
    border-radius: 10px;
    transform: translate(0, 0);
    width: 100%;
    height: 100%;
  }

  &:active::after {
    transition: 0s;
    transform: translate(0, 5%);
  }

  @media (max-width: 768px) {
    width: 80px;
    height: 25px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    width: 60px;
    height: 20px;
    font-size: 12px;
  }
`;

export const DisplayFlex = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const Item = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 35px;
  margin: 0 5px;

  img {
    width: 35px;
    height: 35px;
    border-radius: 50px;
  }

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    margin: 0 4px;

    img {
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 480px) {
    width: 25px;
    height: 25px;
    margin: 0 3px;

    img {
      width: 25px;
      height: 25px;
    }
  }
`;
