import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
  background-color: #fff;
  height: auto;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

  img {
    width: 200px;
  }

  /* Esconde o cabeçalho em celulares pequenos e médios */
  @media only screen and (max-width: 767px) {
    display: none;
  }

  /* Exibe o cabeçalho em tablets */
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    display: flex;
  }
`;

export const HeaderMobile = styled.div`
  display: none; /* Esconde o menu mobile por padrão */
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  background-color: #fff;
  height: auto;
  width: 80%;
  margin: 0 auto;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

  img {
    width: 150px; /* Ajuste do tamanho da imagem para dispositivos menores */
  }

  /* Exibe o menu mobile em celulares pequenos */
  @media only screen and (max-width: 479px) {
    display: flex;
  }

  /* Exibe o menu mobile em celulares médios */
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    display: flex;
  }
`;

export const MenuMobile = styled.div`
  padding: 10px;
  border: 1px solid #d9d9d9;
  font-size: 24px;
  border-radius: 4px;
  position: relative;
  top: -5px;
  margin: 10px;
  height: 25px;
`;

export const AmburguerMenu = styled.div``;

export const Logo = styled.div`
  font-size: 1.5em;
  color: white;
  width:400px;
`;

export const Sidebar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 250px;
  background: #fff;
  color: #000;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  z-index: 1000;
  padding: 20px;
`;

export const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Overlay = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const Menu = styled.nav`
  display: flex;
  gap: 30px;
  margin-left: 200px;

  /* Esconde o menu de navegação em celulares pequenos e médios */
  @media only screen and (max-width: 767px) {
    display: none;
  }

  /* Exibe o menu de navegação em tablets */
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    display: flex;
  }
`;

export const MenuItem = styled.a`
  color: #666666;
  text-decoration: none;
  font-size: 1em;

  &:hover {
    text-decoration: underline;
  }
`;
