import styled from 'styled-components';

export const ServicoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;

  @media (max-width: 555px){
    h1{
        font-size:18px;
    }

    .img{
        display:none;
    }
}
`;

export const Titulo = styled.label`
color: #787878;
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: 300;
`;

export const DisplayFlex = styled.div`
display:flex;

`;

export const ConatinerCards = styled.div`
display:flex;

@media (max-width: 555px){
    &{
        display: inline-grid;
    }
}
`;

export const Card = styled.div`
width: 250px;
height: 400px;
border-radius: 8px;
box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
margin:10px;
background: #fff;


.containerImg{
    width:250px;
    height: 150px;

    img{
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }
}

.font-titulo{
    text-align: left;
    padding: 15px;
    font-size: 17px;
    color: #646363;
    font-weight: 700;
}

.span{
    text-align: left;
    padding: 15px;
    font-size: 14px;
    color: #726e6e;
}

.btn{
    text-align: left;
    padding: 10px;

    a{
        border: none;
        padding: 8px 15px;
        border-radius: 4px;
        background: #24A71C;
        color: #fff;
        text-decoration:none;
    }
}

`;

export const ContainerOutrosServicoMobile = styled.div`
width: 100%;
margin: 10px;
background: #ffffff;
height: auto;
box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
border-radius: 8px;
border: 1px solid #ebebeb;

.conteudo-mob{
    margin:10px;
}

.titulo-mob{
    color: #6e6e6e;
    font-size: 22px;
    font-weight: 300;
}

.text-mob{
    padding: 10px;
    text-align: left;
    font-size: 0.9rem;
    color: #545454;
}
@media only screen and (min-width: 768px) {
    display: none; /* Esconde em tablets e desktops */
  }
`;

export const ContainerOutrosServico = styled.div`
position: relative;
margin-bottom: 60px;
background: #3D59D8;
border-radius: 10px;

.img{
    width: 400px;
    height: auto;
}

.conteudo-card-one{
    width: 400px;
    position: relative;
    left: 70px;
    top:30px;
    height: 530px;
    padding: 20px 30px;
    border-radius: 8px;
    background: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    .fonttitulo{
        color: #646363;
        font-size: 24px;
        text-align: left;
        font-weight: 700;
    }

    .span{
        text-align: left;
        font-size: 18px;
        color: #646363;
    }
}



.conteudo-card-two{
    width: 400px;
    position: relative;
    left: 20px;
    top: -10px;
    height: 220px;
    padding: 10px 30px;
    border-radius: 8px;
    background: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    .fonttitulo{
        color: #646363;
        font-size: 24px;
        text-align: left;
        font-weight: 700;
    }

    .span{
        text-align: left;
        font-size: 18px;
        color: #646363;
    }
}

.btn{
    text-align: left;
    padding: 10px 0;

    button{
        border: none;
        padding: 10px 30px;
        border-radius: 4px;
        background: #3D59D8;
        color: #fff;
    }
}

@media only screen and (max-width: 767px) {
    display: none; /* Esconde em dispositivos móveis */
  }
`;

// Componente Styled para a div fixa
export const FixedDiv = styled.div`
position: fixed;
bottom: 100px;
right: 20px;
background-color: #fff;
padding: 10px;
border-radius: 8px;
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
z-index: 1000;
width: ${({ isMinimized }) => (isMinimized ? '50px' : '170px')};
height: ${({ isMinimized }) => (isMinimized ? '50px' : '200px')};
display: flex;
align-items: center;
justify-content: center;
transition: all 0.3s ease;
`;

// Componente Styled para a imagem
export const QRCodeImage = styled.img`
width: ${({ isMinimized }) => (isMinimized ? '30px' : '150px')};
height: ${({ isMinimized }) => (isMinimized ? '30px' : '150px')};
object-fit: contain;
transition: all 0.3s ease;
`;

export const ToggleButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
`;

// Componente Styled para a imagem do Facebook

export const FacebookDiv = styled.div`
position: fixed;
bottom: 20px;
right: 20px;
height: 70px;
background-color: #fff;
z-index: 1000;
width: 190px;
border-radius: 4px;
display: flex;
align-items: center;
justify-content: center;
transition: all 0.3s ease;
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
border-left: 3px solid #3d59d8;

.alinhaSpan{
    display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Ajuste conforme necessário */
}

.textFace{
    font-size: 16px;
    font-weight: 300;
    color: #828283;
    position: relative;
    left: 10px;
}
`;

export const FacebookImage = styled.img`
  width: 40px;
  height:40px;
  object-fit: contain;
  transition: all 0.3s ease;
  cursor: pointer;
`;